<template>
 <div>
  <svg height="183" viewBox="0 0 183 183" width="183" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradient45" x1="0.657" x2="0.5" y1="-0.101" y2="1.16">
     <stop offset="0" stop-color="#ffe390"/>
     <stop offset="0.602" stop-color="#fab707"/>
     <stop offset="1" stop-color="#db8631"/>
    </linearGradient>
   </defs>
   <g id="icon_BOB" transform="translate(-152 -2337)">
    <rect :fill="background" data-name="Rectangle 174" height="183" id="Rectangle_174" transform="translate(152 2337)" width="183"/>
    <path d="M899.832,532.314l-.074.818-.074,1.041a3.685,3.685,0,0,1-.52,2.007c-.966,1.635-3.2,3.121-6.912,4.533-.372.149-.595-.893-.744-3.122l-.371-6.763c2.824-1.115,5.054-1.487,6.689-1.041l.074.074a2.251,2.251,0,0,1,1.932,2.453Zm-9.216-8.4-.446-8.845a19.127,19.127,0,0,1,5.054-1.487l1.71.223a2.642,2.642,0,0,1,1.709,2.081l.149.446c.149,1.635-.669,3.2-2.527,4.832a10.31,10.31,0,0,1-5.648,2.75Zm48.533,3.493-.446,1.561a9.987,9.987,0,0,1-3.94,4.534,9.707,9.707,0,0,1-5.648,1.635,4.748,4.748,0,0,1-4.087-2.3,5.007,5.007,0,0,1-.149-4.831,9.736,9.736,0,0,1,3.865-4.533,14.16,14.16,0,0,1,2.156-1.041,8,8,0,0,1,3.493-.52,4.921,4.921,0,0,1,4.163,2.229,5.408,5.408,0,0,1,.595,3.27Zm37.83,2.9a7.833,7.833,0,0,1,.372,1.413,4.609,4.609,0,0,1-2.676,4.682,9.87,9.87,0,0,1-6.838.817l1.115-9.737c1.263-.743,2.824-.669,4.831.074l.743.3a4.644,4.644,0,0,1,2.453,2.453Zm-6.466-19.621a15.336,15.336,0,0,1,5.871,0,3.528,3.528,0,0,1,2.75,2.155,4.113,4.113,0,0,1,.074,1.932c-.446,3.568-3.642,5.351-9.514,5.426l.818-9.513Zm-11.669-6.021-.743,1.784-3.791,9.142c-6.02-4.905-12.486-6.986-19.473-6.318a28.881,28.881,0,0,0-13.378,5.277c-4.014,2.675-6.763,5.351-8.4,8.176-3.642-1.189-5.871-1.56-6.689-1.189l.595-.669a15.983,15.983,0,0,0,2.453-2.6,10.612,10.612,0,0,0,2.007-10.108c-1.041-3.27-3.865-4.905-8.4-4.979a28.661,28.661,0,0,0-9.216,1.486c-2.229.669-7.506,3.271-15.682,7.8l-3.79,44q18.729,2.453,36.567-12.114c8.734,9.981,25.8,6.232,34.337-1.71l-2.527,5.648c7.968,5.071,20.229,4.067,28.951,2.512C979,549.5,984.432,547.765,987.9,545.4a14.84,14.84,0,0,0,3.642-3.568,16.03,16.03,0,0,0,3.122-8.1c.595-4.385.074-7.581-1.635-9.513a9.642,9.642,0,0,0-2.453-1.858l-2.527-1.263c5.128-3.271,6.987-7.878,5.426-13.676-1.859-7.507-13.452-8.4-34.634-2.75Z" data-name="Path 380" fill="url(#linear-gradient45)" fill-rule="evenodd" id="Path_380" transform="translate(-691.121 1899.581)"/>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>